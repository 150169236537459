import React, { useState } from 'react';
import {
  Container,
  Header,
  FilterBar,
  SearchInput,
  AddButton,
  Table,
  StatusBadge,
  Pagination,
  ModalOverlay,
  ModalContent,
  CloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TabContainer,
  Tab,
  Form,
  TextField,
  TextArea,
  UploadArea,
  WarningMessage,
  ProgressBarContainer,
  ProgressBar,
  HeaderImport,
} from './style';

import lapis from './assets/lapis.png';
import profilePlaceholder from './assets/upload.png';

const Clientes = () => {
  const [clients, setClients] = useState([
    {
      name: 'Cliente Fulano',
      email: 'email@email.com.br',
      phone: '3199999999',
      status: 'Em dia',
    },
    {
      name: 'Cliente Fulano',
      email: 'email@email.com.br',
      phone: '3199999999',
      status: 'A fazer',
    },
    {
      name: 'Cliente Fulano',
      email: 'email@email.com.br',
      phone: '3199999999',
      status: 'Risco',
    },
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const renderStatusBadge = (status: any) => {
    switch (status) {
      case 'Em dia':
        return <StatusBadge color="green">● Em dia</StatusBadge>;
      case 'A fazer':
        return <StatusBadge color="orange">● A fazer</StatusBadge>;
      case 'Risco':
        return <StatusBadge color="red">● Risco</StatusBadge>;
      default:
        return null;
    }
  };

  const [activeTab, setActiveTab] = useState('addClient');

  return (
    <Container>
      <Header>
        <h1>Clientes</h1>
        <AddButton onClick={openModal}>Adicionar cliente +</AddButton>
      </Header>

      <FilterBar>
        <select>
          <option value="">Tudo</option>
          <option value="Em dia">Em dia</option>
          <option value="A fazer">A fazer</option>
          <option value="Risco">Risco</option>
        </select>
        <SearchInput placeholder="Pesquisar" />
      </FilterBar>

      <FilterBar>
        <nav>
          <a href="#">
            Tudo <span>27</span>
          </a>
          <a href="#">
            Tarefa <span>4</span>
          </a>
          <a href="#">
            Aguardando <span>4</span>
          </a>
          <a href="#">
            Em dia <span>12</span>
          </a>
          <a href="#">
            Arquivado <span>9</span>
          </a>
        </nav>
      </FilterBar>

      <Table>
        <thead>
          <tr>
            <th>Nome</th>
            <th>E-mail</th>
            <th>Telefone</th>
            <th>Status</th>
            <th>Editar</th>
          </tr>
        </thead>
        <tbody>
          {clients.map((client, index) => (
            <tr key={index}>
              <td>{client.name}</td>
              <td>{client.email}</td>
              <td>{client.phone}</td>
              <td>{renderStatusBadge(client.status)}</td>
              <td>
                <button>
                  <img src={lapis} alt="Editar" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Pagination>
        <span>1-2 de 2</span>
        <div>
          <span>Itens por página: 15</span>
          <button>{'<'}</button>
          <span>1 / 2</span>
          <button>{'>'}</button>
        </div>
      </Pagination>

      {isModalOpen && (
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>
              <TabContainer>
                <Tab
                  active={activeTab === 'addClient'}
                  onClick={() => setActiveTab('addClient')}
                >
                  Adicionar cliente
                </Tab>
                <Tab
                  active={activeTab === 'importClients'}
                  onClick={() => setActiveTab('importClients')}
                >
                  Importar clientes
                </Tab>
              </TabContainer>
              <CloseButton onClick={closeModal}>×</CloseButton>
            </ModalHeader>
            <ModalBody>
              {activeTab === 'addClient' && (
                <Form>
                  <div>
                    <label>Nome completo</label>
                    <TextField type="text" placeholder="Nome do cliente" />
                    <label>Endereço</label>
                    <TextField type="text" placeholder="Endereço" />
                    <label>Email</label>
                    <TextField type="email" placeholder="Email do cliente" />
                    <label>Telefone</label>
                    <TextField type="text" placeholder="Telefone" />
                  </div>
                  <div>
                    <label>Informações adicionais</label>
                    <TextArea placeholder="Adicione informações extras..." />
                  </div>
                </Form>
              )}
              {activeTab === 'importClients' && (
                <>
                  <HeaderImport>
                    <UploadArea>
                      <img src={profilePlaceholder} alt="Profile" />
                      <p>
                        Arraste ou <span>Clique para upload</span>
                      </p>
                      <small>Max. 5MB | CSV , Excel</small>
                    </UploadArea>
                    <div>
                      <WarningMessage>
                        <span>Atenção</span> O arquivo precisa estar nos moldes
                        para realizar o upload.
                        {/* <button onClick={closeModal}>×</button> */}
                      </WarningMessage>
                      <ProgressBarContainer>
                        <p>Nome do documento.xpto</p>
                        <ProgressBar progress={30} />
                        <small>xMB</small>
                      </ProgressBarContainer>
                    </div>
                  </HeaderImport>

                  <Table>
                    <thead>
                      <tr>
                        <th>Cliente</th>
                        <th>Telefone</th>
                        <th>E-mail</th>
                        <th>Editar</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Gustavo</td>
                        <td>31999999999</td>
                        <td>email@email.com.br</td>
                        <td>
                          <button>
                            <img src={lapis} alt="Editar" />
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <button onClick={closeModal}>Cancelar</button>
              <button type="submit">Adicionar cliente</button>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      )}
    </Container>
  );
};

export default Clientes;
